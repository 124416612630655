import React, { useEffect } from 'react';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PreviewIcon from '@mui/icons-material/Preview';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Button from '@mui/material/Button';

export default function RutinaHistorico(props) {
  const [arrayDatos, setArrayDatos] = React.useState([]);
  
  useEffect(() => {
    getDatos();    
  }, [props.userArray.id]);

  function getDatos(){
    axios.post(props.ruta + "backend/clientes/get_datos_rutinas.php",{
      idCliente: props.arrayDatos.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {     
        setArrayDatos(data);
      }
    })
  }

  function irAtras(){
    props.cambiarPag('');
  }

  const renderBotonVer = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("9")) {      
      return(   
        <Tooltip title="Ver rutina">
          <IconButton style={{color:'#082342'}}  onClick={() => ver(datos)}>
            <PreviewIcon />
          </IconButton>
        </Tooltip> 
      )
    }
  }  

  function ver(a){
    props.cambiarPag('rutinaHistoricoVer');
    props.setIdRutina(a);
  }

  const renderBotonActivar = (idCliente, datos) => {
    if(datos.activo !== 1 && datos.activo !== '1'){
      return(
        <Button onClick={() => activar(idCliente, datos.id,)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
          Activar
        </Button>
      )
    }
  } 

  function activar(idCliente, idRutina){
    axios.post(props.ruta + "backend/clientes/rutina_activar.php",{
      userId: props.userArray.id,
      idCliente: idCliente,
      idRutina: idRutina
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {     
        getDatos();
      }
    })
  }

  const renderBotonDuplicar = (idCliente, idRutina) => {
    if (props.checkPermisos("1") || props.checkPermisos("31")) {      
      return(   
        <Tooltip title="Duplicar rutina">
          <IconButton style={{color:'#082342'}}  onClick={() => duplicar(idCliente, idRutina)}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip> 
      )
    }
  } 

  function duplicar(idCliente, idRutina){    
    axios.post(props.ruta + "backend/clientes/rutina_duplicar.php",{
      userId: props.userArray.id,
      idCliente: idCliente,
      idRutina: idRutina
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {     
        getDatos();
      }
    })
  }

  return (
    <Box sx={{ minWidth: '100%'}}>    
      <Grid container xs={12} spacing={2}>
        <Grid item xs={1}>
          <Tooltip title="Atras">
            <IconButton style={{color:'#000000'}}  onClick={() => irAtras('')}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <TableContainer>
            <Table
              sx={{ minWidth: '99%' }}
              aria-labelledby="tableTitle"
              size='medium'
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align='center'
                    style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '30%'}}
                  >           
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '50%'}}
                  >
                    Fecha
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '200%'}}
                  >
                    Activo
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arrayDatos.map((row, index) => {
                  var act = row.activo;
                  act = parseInt(act);
                  return (
                    <TableRow
                      style={{backgroundColor: act === 1? '#ccff90':'' }}
                    >
                      <TableCell align='center'>
                        {renderBotonVer(row.id)}
                        {renderBotonDuplicar(props.arrayDatos.id, row.id)}
                      </TableCell>
                      <TableCell align='center'> {row.fechaFormato} </TableCell>
                      <TableCell align='center'>
                        {renderBotonActivar(props.arrayDatos.id, row)}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer> 
        </Grid>

      </Grid>
    </Box>
  );
}