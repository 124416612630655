import React, { useEffect } from 'react';
import axios from "axios";
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import AcordionEjercicio from './Clientes/Rutina/AcordionEjercicio';
import TouchAppIcon from '@mui/icons-material/TouchApp';

export default function HomeCliente(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [arrayDias, setArrayDias] = React.useState([]);
  const [dia, setDia] = React.useState(0);
  const [arrayDatosRutina, setArrayDatosRutina] = React.useState([]); 
  const [rutinaId, setRutinaId] = React.useState(''); 
  const [rutinaSemana, setRutinaSemana] = React.useState(''); 
  const [rutinaInicio, setRutinaInicio] = React.useState('');
  const [rutinaFin, setRutinaFin] = React.useState('');
  const [rutinaDuracion, setRutinaDuracion] = React.useState('');
  const [rutinaFecha, setRutinaFecha] = React.useState(''); 

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      }
    } else {
      if(!props.checkPermisos("1") && !props.checkPermisos("0")) {                    
        navigate('/');
      }
    }
    props.setPagina('clienteRutina'); cookies.set('sethmon_pagina', 'clienteRutina');
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  useEffect(() => {    
    getDias();
  }, [props.userArray.id]);

  function getDias(){
    axios.post(props.ruta + "backend/cliente_rutina/get_dias.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayDias(data);
        setRutinaFecha(data[0].fechaFormato);

      } 
    })
  }

  const renderBtnsDias = () => {    
    if(dia === 0) {      
      return(
        <>      
          {renderDia1()}           
          {renderDia2()} 
          {renderDia3()} 
          {renderDia4()} 
          {renderDia5()} 
          {renderDia6()} 
          {renderDia7()} 
        </>
      )
    }
  }

  const renderDia1 = () => {       
    return(
      <>
        {arrayDias.map((row, index) => {
          if(row.dia1	=== 1 || row.dia1	=== '1') {
            return(
                <Grid xs={6}>  
                  <Button onClick={() => cambiarDia(1)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
                    Día 1
                  </Button>
                </Grid>
            )
          }
        })} 
      </>
    )
  }

  const renderDia2 = () => {       
    return(
      <>
        {arrayDias.map((row, index) => { 
          if(row.dia2	=== 1 || row.dia2	=== '1') {
            return(
                <Grid xs={6}>  
                  <Button onClick={() => cambiarDia(2)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
                    Día 2
                  </Button>
                </Grid>
            )
          }
        })} 
      </>
    )
  }

  const renderDia3 = () => {       
    return(
      <>
        {arrayDias.map((row, index) => {     
          if(row.dia3	=== 1 || row.dia3	=== '1') {
            return(
                <Grid xs={6}>  
                  <Button onClick={() => cambiarDia(3)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
                    Día 3
                  </Button>
                </Grid>
            )
          }
        })} 
      </>
    )
  }

  const renderDia4 = () => {       
    return(
      <>
        {arrayDias.map((row, index) => {     
          if(row.dia4	=== 1 || row.dia4	=== '1') {
            return(
                <Grid xs={6}>  
                  <Button onClick={() => cambiarDia(4)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
                    Día 4
                  </Button>
                </Grid>
            )
          }
        })} 
      </>
    )
  }

  const renderDia5 = () => {       
    return(
      <>
        {arrayDias.map((row, index) => {     
          if(row.dia5	=== 1 || row.dia5	=== '1') {
            return(
              <Grid xs={6}>  
                <Button onClick={() => cambiarDia(5)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
                  Día 5
                </Button>
              </Grid>
            )
          }
        })} 
      </>
    )
  }

  const renderDia6 = () => {       
    return(
      <>
        {arrayDias.map((row, index) => {     
          if(row.dia6	=== 1 || row.dia6	=== '1') {
            return(
                <Grid xs={6}>  
                  <Button onClick={() => cambiarDia(6)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
                    Día 6
                  </Button>
                </Grid>
            )
          }
        })} 
      </>
    )
  }

  const renderDia7 = () => {       
    return(
      <>
        {arrayDias.map((row, index) => {     
          if(row.dia7	=== 1 || row.dia7	=== '1') {
            return(
                <Grid xs={6}>  
                  <Button onClick={() => cambiarDia(7)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
                    Día 7
                  </Button>
                </Grid>
            )
          }
        })} 
      </>
    )
  }

  function cambiarDia(a){
    setDia(a);
    getDatosRutina(a);    
    getTiempos(a);
  }

  function getDatosRutina(a){
    axios.post(props.ruta + "backend/cliente_rutina/get_datos.php",{
      userId: props.userArray.id,
      dia: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayDatosRutina(data);
      } 
    })
  } 

  function getTiempos(a){
    axios.post(props.ruta + "backend/cliente_rutina/get_tiempos.php",{
      userId: props.userArray.id,
      dia: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setRutinaId(data[0].id_rutina);
        setRutinaSemana(data[0].semana);
        setRutinaInicio(data[0].inicio);
        setRutinaFin(data[0].fin);
        setRutinaDuracion(data[0].duracion);
      } 
    })
  }

  const renderRutina = () => {    
    if(dia !== 0) {      
      return(
        <>
          <Grid item xs={1} style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
            <Tooltip title="Atras">
              <IconButton style={{color:'#ffffff'}}  onClick={() => cambiarDia(0)}>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={10} style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
            <Typography variant="h6" style={{color: '#ffffff', textAlign: 'center'}}>
            {"Día " + dia} 
            </Typography>
          </Grid>
          <Grid item xs={1} style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
          </Grid>

          {renderBtnInicio()}

          <Grid item xs={12}>
            
            {arrayDatosRutina.map((row, index) => {
                console.log(rutinaInicio)
              return (
                <AcordionEjercicio
                {...props}
                row = {row}
                getDatosRutina = {getDatosRutina}
                dia = {dia}
                rutinaInicio = {rutinaInicio}
                rutinaFin = {rutinaFin}
                />
              )
            })}            
          </Grid>
          
          {renderBtnFin()}
        </>
      )
    }
  }
  
  const renderBtnInicio = () => {
    if(rutinaInicio === '00:00:00 00-00-0000'){
      return(
        <Grid xs={12}>  
          <Button onClick={() => registroIniFin(1)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
          <Tooltip>
              <IconButton style={{color:'#ffffff'}}>
                <TouchAppIcon />
              </IconButton>
            </Tooltip>
            Iniciar entrenamiento
          </Button>
        </Grid>
      )
    } else {
      return(
        <Grid item xs={12}>          
          <Typography variant="h6" style={{backgroundColor: '#ffffff', textAlign: 'center'}}>
            {'Inicio: ' + rutinaInicio}
            </Typography>
        </Grid>
      )
    }
  }
  
  const renderBtnFin = () => {
    if(rutinaFin === '0000-00-00 00:00:00'){
      return(
        <Grid xs={12}>  
          <Button onClick={() => registroIniFin(2)} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
            <Tooltip>
              <IconButton style={{color:'#ffffff'}}>
                <TouchAppIcon />
              </IconButton>
            </Tooltip>
            Terminar entrenamiento
          </Button>
        </Grid>
      )
    } else if(rutinaFin !== 0 && rutinaFin !== '0'){
      return(
        <>
          <Grid item xs={12}>          
            <Typography variant="h6" style={{backgroundColor: '#ffffff', textAlign: 'center'}}>
              {'Fin: ' + rutinaFin}
              </Typography>
          </Grid>
          <Grid item xs={12}>          
            <Typography variant="h6" style={{backgroundColor: '#ffffff', textAlign: 'center'}}>
              {'Duración: ' + rutinaDuracion}
              </Typography>
          </Grid>
        </>
      )
    }
  }

  function registroIniFin(a){
    axios.post(props.ruta + "backend/cliente_rutina/registro_ini_fin.php",{
      userId: props.userArray.id, 
      rutinaId: rutinaId,
      dia: dia,
      inicio: rutinaInicio,
      tipo: a
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        getTiempos(dia);
      } 
    })
  }
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>          
          <Typography variant="h6" style={{backgroundColor: '#ffffff', textAlign: 'center'}}>
            {'Rutina creada el día ' + rutinaFecha}
            </Typography>
        </Grid>

        {renderBtnsDias()}
        {renderRutina()}
           
      </Grid>

    </Box>
  );
}