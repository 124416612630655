import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  }
})

export default function ClienteReporteSemanal(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [peso, setPeso] = React.useState('');
  const [agua, setAgua] = React.useState('');
  const [sueno, setSueno] = React.useState('');
  const [pasos, setPasos] = React.useState('');
  const [seguimiento, setSeguimiento] = React.useState(0);
  const [hambre, setHambre] = React.useState(0);
  const [malEstoma, setMalEstoma] = React.useState(0);
  const [malEstomaComent, setMalEstomaComent] = React.useState('');
  const [sensacion, setSensacion] = React.useState('');
  const [progreso, setProgreso] = React.useState('');
  const [recuperacion, setRecuperacion] = React.useState(0);
  const [cambios, setCambios] = React.useState('');
  const [etapa, setEtapa] = React.useState('');
  const [envaramiento, setEnvaramiento] = React.useState('');
  const [comentarios, setComentarios] = React.useState('');

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      }
    }
  }, []);

  function guardar(){
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if(ban === 0){
      axios.post(props.ruta + "backend/clientes/reporte_semanal_agregar.php", {
          userId: props.userArray.id,
          peso: peso,
          agua: agua,
          sueno: sueno,
          pasos: pasos,
          seguimiento: seguimiento,
          hambre: hambre,
          malEstoma: malEstoma,
          malEstomaComent: malEstomaComent,
          sensacion: sensacion,
          progreso: progreso,
          recuperacion: recuperacion,
          cambios: cambios,
          etapa: etapa,
          envaramiento: envaramiento,
          comentarios: comentarios
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            setPeso('');
            setAgua('');
            setSueno('');
            setPasos('');
            setSeguimiento(0);
            setHambre(0);
            setMalEstoma(0);
            setMalEstomaComent('');
            setSensacion('');
            setProgreso('');
            setRecuperacion(0);
            setCambios('');
            setEtapa('');
            setEnvaramiento('');
            setComentarios('');
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("Los datos se han guardado con exito");
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } 
  }
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}  justifyContent="center">
        
        <Grid item xs={1} style={{backgroundColor: '#1769aa', color: '#ffffff', textAlign: 'center'}}>
          <Tooltip title="Atras">
            <IconButton style={{color:'#ffffff'}}  onClick={() => navigate('/homeCliente')}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={11} style={{backgroundColor: '#1769aa'}}>  
          <Typography variant="h6" style={{color: '#ffffff', textAlign: 'center'}}>
          Reporte semanal
          </Typography>
        </Grid>

        <Grid container xs={12} spacing={3} justifyContent={'center'} style={{marginTop: '%'}} >
          
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="Último peso" variant="outlined" style={{width:'100%'}}               
              value={peso}
              onChange={(event) => setPeso(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="Litros de agua promedio" variant="outlined" style={{width:'100%'}}               
              value={agua}
              onChange={(event) => setAgua(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="Horas de sueño promedio" variant="outlined" style={{width:'100%'}}               
              value={sueno}
              onChange={(event) => setSueno(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="Pasos promedio" variant="outlined" style={{width:'100%'}}               
              value={pasos}
              onChange={(event) => setPasos(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left'}}>
            <Typography 
              component="legend"
              variant="body1"
            >
              Seguimiento a tu plan del 1 al 10
            </Typography>
            <Rating 
              name="customized-10" 
              value={seguimiento} 
              max={10} 
              onChange={(event) => setSeguimiento(event.target.value)}              
            />
          </Grid>
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left', marginTop: '-5%', marginBottom: '-2%'}}>
            <Divider /> 
          </Grid>      
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left'}}>
            <Typography 
              component="legend"
              variant="body1"
            >
              Nivel de hambre del 1 al 10
            </Typography>
            <Rating 
              name="customized-10" 
              value={hambre} 
              max={10} 
              onChange={(event) => setHambre(event.target.value)}
            />
            <Typography 
              component="legend"
              variant="caption"
              style={{color: '#616161'}}
            >
              (1 es apenas consigo comer y 10 es muchisima hambre)
            </Typography>
          </Grid>             
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left', marginTop: '-2%', marginBottom: '-2%'}}>
            <Divider /> 
          </Grid>       
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left'}}>
            <Typography 
              component="legend"
              variant="body1"
            >
              Malestares estomacales del 1 al 10
            </Typography>
            <Rating 
              name="customized-10" 
              value={malEstoma} 
              max={10} 
              onChange={(event) => setMalEstoma(event.target.value)}
            />
            <Typography 
              component="legend"
              variant="caption"
              style={{color: '#616161'}}
            >
              Menciona si existe alguna molestia partituclar
            </Typography>
            <TextField  variant="outlined" style={{width:'100%'}}               
              value={malEstomaComent}
              onChange={(event) => setMalEstomaComent(event.target.value)}
            />
          </Grid>             
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left', marginTop: '-2%', marginBottom: '-2%'}}>
            <Divider /> 
          </Grid>  
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="¿Molestias?" variant="outlined" style={{width:'100%'}}               
              value={sensacion}
              onChange={(event) => setSensacion(event.target.value)}
              helperText="Menciona si algun ejercicio de tu entrenamiento no te genera buenas sensaciones o gener molestias al realizarlo"
            />
          </Grid>
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="Progreso en tu entrenamiento" variant="outlined" style={{width:'100%'}}               
              value={progreso}
              onChange={(event) => setProgreso(event.target.value)}
              helperText="(Mejoras en cargas, ejecucion, reps, etc)"
            />
          </Grid>           
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left', marginTop: '-2%', marginBottom: '-2%'}}>
            <Divider /> 
          </Grid> 
          
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left'}}>
            <Typography 
              component="legend"
              variant="body1"
            >
              Recuperación entre sesiones del 1 al 10
            </Typography>
            <Rating 
              name="customized-10" 
              value={recuperacion} 
              max={10} 
              onChange={(event) => setRecuperacion(event.target.value)}
            />
            <Typography 
              component="legend"
              variant="caption"
              style={{color: '#616161'}}
            >
              (1 es no hay fatiga, dolor o cansancio y 10 es muchisimo dolor, fatiga o cansancio)
            </Typography>
          </Grid>             
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'left', marginTop: '-2%', marginBottom: '-2%'}}>
            <Divider /> 
          </Grid> 
          
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="Cambios fisicos" variant="outlined" style={{width:'100%'}}               
              value={cambios}
              onChange={(event) => setCambios(event.target.value)}
              helperText="(Solo si los hay)"
            />
          </Grid>  
          
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="Etapa actual" variant="outlined" style={{width:'100%'}}               
              value={etapa}
              onChange={(event) => setEtapa(event.target.value)}
              helperText="(Deficit / Mini cut o Massing / Volumen)"
            />
          </Grid>

          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="¿Existe dolor de envaramiento de la sesion anterior?" variant="outlined" style={{width:'100%'}}               
              value={envaramiento}
              onChange={(event) => setEnvaramiento(event.target.value)}
            />
          </Grid> 
          
          <Grid item xs={12} style={{backgroundColor: '#ffffff'}}>
            <TextField label="Comentarios que consideras importantes" variant="outlined" style={{width:'100%'}}               
              value={comentarios}
              onChange={(event) => setComentarios(event.target.value)}
            />
          </Grid> 
          
          <Grid item xs={12} style={{backgroundColor: '#ffffff', textAlign: 'center'}}>
            <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
              Guardar
            </Button>
          </Grid>

        </Grid>    
      </Grid>
    </Box>
  );
}