import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Unstable_Grid2';
import axios from "axios";
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import DialogoPagoEditar from './DialogoPagoEditar';
import DialogoPagoEliminar from './DialogoPagoEliminar';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoHistoricoPagos(props) { 
  const [arrayDatos, setArrayDatos] = React.useState([]);
  const [arrayDatosAfectados, setArrayDatosAfectados] = React.useState([]);
  const [dialogoPagoEditar, setDialogoPagoEditar] = React.useState(false);
  const [dialogoPagoEliminar, setDialogoPagoEliminar] = React.useState(false);

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {
    getDatos();    
  }, [props.userArray.id]);

  function getDatos(){
    axios.post(props.ruta + "backend/clientes/get_pagos_historico.php",{
      idCliente: props.arrayDatos.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {     
        setArrayDatos(data);
      }
    })
  }

  const renderBotonEditar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("29")) {      
      return(   
        <Tooltip title="Editar">
          <IconButton style={{color:'#082342'}}  onClick={() => editar(datos)}>
            <EditIcon />
          </IconButton>
        </Tooltip> 
      )
    }
  }

  function editar(i){
    setArrayDatosAfectados(i);
    setDialogoPagoEditar(true);
  }

  const renderDialogoPagoEditar = () =>{    
    if(dialogoPagoEditar === true){
      return(
        <DialogoPagoEditar
          {...props}     
          open = {dialogoPagoEditar}
          setOpen = {setDialogoPagoEditar}
          getDatos = {getDatos}
          arrayDatosAfectados = {arrayDatosAfectados}
        />
      )
    }
  }

  /// eliminar
  const renderBotonEliminar = (datos) => {
    if (props.checkPermisos("1") || props.checkPermisos("30")) {      
      return(   
        <Tooltip title="Eliminar">
          <IconButton style={{color:'#ab003c'}}  onClick={() => eliminar(datos)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip> 
      )
    }
  }

  function eliminar(i){
    setArrayDatosAfectados(i);
    setDialogoPagoEliminar(true);
  }

  const renderDialogoPagoEliminar = () =>{    
    if(dialogoPagoEliminar === true){
      return(
        <DialogoPagoEliminar
          {...props}     
          open = {dialogoPagoEliminar}
          setOpen = {setDialogoPagoEliminar}
          getDatos = {getDatos}
          arrayDatosAfectados = {arrayDatosAfectados}
        />
      )
    }
  }
  ////////////

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth='sm'
        fullWidth={true}
      >        
        <DialogTitle id="customized-dialog-title" sx={{ m:0, p:2}} style={{backgroundColor: '#082342', color: '#ffffff' }}>
        Histórico de pagos de {props.arrayDatos.nombre + ' ' + props.arrayDatos.aPaterno+ ' ' + props.arrayDatos.aMaterno}
        </DialogTitle>        
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  sx={{ minWidth: '99%' }}
                  aria-labelledby="tableTitle"
                  size='medium'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align='center'
                        style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '20%'}}
                      >           
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '30%'}}
                      >
                        Fecha
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '20%'}}
                      >
                        Semanas
                      </TableCell>
                      <TableCell
                        align='center'
                        style={{ backgroundColor: '#082342', color: '#ffffff', cursor: 'default', width: '30%'}}
                      >
                        Monto
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {arrayDatos.map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell>
                            {renderBotonEditar(row)}
                            {renderBotonEliminar(row)}
                          </TableCell>
                          <TableCell align='center'> {row.fecha_formato} </TableCell>
                          <TableCell align='center'> {row.semanas} </TableCell>
                          <TableCell align='right'> {row.pago_monto_formato} </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer> 
            </Grid>
          </Grid>

        </DialogContent>

        {renderDialogoPagoEditar()}
        {renderDialogoPagoEliminar()}

      </BootstrapDialog>
    </React.Fragment>
  );
}