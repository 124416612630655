import React, { useEffect } from 'react';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import RepeatIcon from '@mui/icons-material/Repeat';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';

export default function AcordionEjercicio(props) {  
  const [expanded, setExpanded] = React.useState(false);
  const [repsId, setRepsId] = React.useState('');
  const [reps, setReps] = React.useState('');
  const [repsRer, setRepsRer] = React.useState('');
  const [peso, setPeso] = React.useState('');
  const [medida, setMedida] = React.useState('');  
  const [banHistorialActual, setBanHistorialActual] = React.useState(false);
  const [arrayHistorialActual, setArrayHistorialActual] = React.useState([]);
  const [fechaistorialActual, setFechaHistorialActual] = React.useState(''); 
  const [banHistorialPrevio, setBanHistorialPrevio] = React.useState(false);
  const [arrayHistorialPrevio, setArrayHistorialPrevio] = React.useState([]);
  const [fechaistorialPrevio, setFechaHistorialPrevio] = React.useState('');   
  const [arrayPromedio, setArrayPromedio] = React.useState([]);
  const [banHecho, setBanHecho] = React.useState(false);
  
  
  useEffect(() => {
    setRepsId(props.row.id);
    getDatosActual();
    getDatosHistorico();
    getDatosPromedio();
  }, [props.userArray.id]);  
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getDatosActual(){
    axios.post(props.ruta + "backend/cliente_rutina/get_datos_historico_actual.php",{
      userId: props.userArray.id,
      ejercicio: props.row.ejercicio,
      id_musculo: props.row.id_musculo,
      rep: props.row.rep,
      rer: props.row.rer
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayHistorialActual(data);
        setBanHistorialActual(true);
        setFechaHistorialActual(data[0].fecha);
        
        if(parseInt(data[0].cant) === parseInt(props.row.sets)){
          setBanHecho(true);
        }
      } 
    })
  }

  function getDatosHistorico(){
    console.log('2 - ' + props.rutinaFin)
    axios.post(props.ruta + "backend/cliente_rutina/get_datos_historico_previo.php",{
      userId: props.userArray.id,
      ejercicio: props.row.ejercicio,
      id_musculo: props.row.id_musculo,
      rep: props.row.rep,
      rer: props.row.rer,
      rutinaInicio: props.rutinaInicio,
      rutinaFin: props.rutinaFin
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayHistorialPrevio(data);
        setBanHistorialPrevio(true);
        setFechaHistorialPrevio(data[0].fecha);

        if(data[0].banSemana === 1 ||data[0].banSemana === '1'){
          setBanHecho(true);
        }
      } 
    })
  }

  function getDatosPromedio(){
    axios.post(props.ruta + "backend/cliente_rutina/get_datos_historico_promedio.php",{
      userId: props.userArray.id,
      ejercicio: props.row.ejercicio,
      id_musculo: props.row.id_musculo,
      rep: props.row.rep,
      rer: props.row.rer
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setArrayPromedio(data[0]);
      } 
    })
  }

  function guardar(){
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (reps === "") { ban = 1; } 
    if (repsRer === "") { ban = 1; } 
    if (peso === "") { ban = 1; } 

    if(ban === 0){
      axios.post(props.ruta + "backend/cliente_rutina/agregar.php", {
          userId: props.userArray.id,
          repsId: repsId,          
          reps: reps,
          repsRer: repsRer,
          peso: peso,
          medida: medida,
          ejercicio: props.row.ejercicio,
          id_musculo: props.row.id_musculo,
          reps_env: props.row.rep,
          rer_env: props.row.rer
      })
      .then(result => {
          var data = result.data;

          if (data[0].error === 0) {
            props.setDialogNotificacionTipo('Exito');
            props.setDialogNotificacionMensaje("Se ha guardado correctamente")          
            getDatosActual();
            getDatosHistorico();
            getDatosPromedio();            
          } else {
            props.setDialogNotificacionTipo('Error');
            props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
          }

      });      
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en el campo "Monto $"');
    } 
  }

  const renderIcono = (c, m) => {    
    if(c === 1){
      return(
        <IconButton style={{color:'#000000'}} >
          <TurnRightIcon />
        </IconButton>
      )
    } else if(c === 2 && m === 6){
      return(
        <IconButton style={{color:'#000000'}} >
          <SwapVertIcon />
        </IconButton>
      )
    } else if(c === 2 && m === 5){
      return(
        <IconButton style={{color:'#000000'}} >
          <SubdirectoryArrowRightIcon />
        </IconButton>
      )
    } else if(c === 3){
      return(
        <IconButton style={{color:'#000000'}} >
          <SubdirectoryArrowRightIcon />
        </IconButton>
      )
    }
  }

  const renderTipo = (c,m) => {
    if(m === 5 && c === 1){
      return(
        <Grid item xs={12}>          
          <Typography variant="body1" style={{backgroundColor: '#ffffff', textAlign: 'center'}}>
            BISERIE
            </Typography>
        </Grid>
      )
    } else if(m === 6 && c === 1){
      return(
        <Grid item xs={12}>          
          <Typography variant="body1" style={{backgroundColor: '#ffffff', textAlign: 'center'}}>
            TRISERIE
            </Typography>
        </Grid>
      )
    } else  if(m !== 5 && m !== 6){
      return(
        <Grid item xs={12}>          
          <Typography variant="body1" style={{backgroundColor: '#ffffff', minHeight: 1}}></Typography>
        </Grid>
      )
    }    
  }  

  const renderHistorialActual = () => {
    if(banHistorialActual){
      return(
        <Grid container xs={12} style={{alignItems:'center', borderTop: '1px solid #757575', marginTop: '3%'}}>
          <Grid item xs={12} style={{color: '#000000', textAlign: 'left', marginTop: '3%', marginBottom: '1%'}}>
            <Typography variant="subtitle2" style={{color: '#0277bd'}}>
              Registros
            </Typography>
          </Grid>
          {arrayHistorialActual.map((row, index) => {         
            return(  
              <>
                <Grid item xs={12} style={{color: '#000000', textAlign: 'left'}}>
                  <Typography variant="body2">
                  {row.n + ') ' + row.peso + ' ' + row.medida + ' | Reps: ' + row.reps + ' | RER: ' + row.rer}
                  </Typography>          
                </Grid>
              </>
            )
          })}        
        </Grid>
      )
    }
  }

  const renderHistorialPrevio = () => {
    if(banHistorialPrevio){
      return(
        <Grid container xs={12} style={{alignItems:'center', borderTop: '1px solid #757575', marginTop: '3%'}}>
          <Grid item xs={8} style={{color: '#000000', textAlign: 'left', marginTop: '2%', marginBottom: '1%'}}>
            <Typography variant="subtitle2" style={{color: '#0277bd'}}>
              Registros previos
            </Typography>
          </Grid>
          <Grid item xs={4} style={{color: '#000000', textAlign: 'right'}}>
            <Typography variant="subtitle2">
            {fechaistorialPrevio}
            </Typography>          
          </Grid>
          {arrayHistorialPrevio.map((row, index) => {         
            return(  
              <>
                <Grid item xs={12} style={{color: '#000000', textAlign: 'left'}}>
                  <Typography variant="body2">
                  {row.n + ') ' + row.peso + ' ' + row.medida + ' | Reps: ' + row.reps + ' | RER: ' + row.rer}
                  </Typography>          
                </Grid>
              </>
            )
          })}        
        </Grid>
      )
    }
  }

  const renderBtnRegistro = () => {
    if(!banHecho){
      return(
        <Grid item xs={12}>
          <Button onClick={() => guardar()} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff', minHeight: '100%', minWidth: '100%'}}>
            Registrar
          </Button>
        </Grid>
      )
    } else {
      return(
        <Grid item xs={12} style={{textAlign: 'center'}}>
          <Typography variant="subtitle2" style={{color: '#0277bd'}}>
          Ya se cumplieron los registros de esta semana
          </Typography>          
        </Grid>
      )
    }
  }

  const renderConfigRecom = () => {
    if(arrayPromedio.peso){
      return(      
        <Grid item xs={12}>
          <Typography variant="body2" style={{color: '#0277bd', textAlign: 'left'}}>
          {arrayPromedio.peso + ' ' + arrayPromedio.medida + ' | Reps: ' + arrayPromedio.reps + ' | RER: '+ arrayPromedio.rer}
          </Typography>          
        </Grid>
      )
    } else {    
      return( 
        <Grid item xs={12}>
          <Typography variant="body2" style={{color: '#0277bd', textAlign: 'left'}}>
          No se tienen registros previos
          </Typography>          
        </Grid>)      
    }
  }

  return (       
    <>
      {renderTipo(props.row.contar, props.row.id_metodo)}
      <Accordion expanded={expanded === props.row.n} onChange={handleChange(props.row.n)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{backgroundColor: props.colorDos}}
        >
          {renderIcono(props.row.contar, props.row.id_metodo)}
          <Typography variant="body1" sx={{ width: '10%', flexShrink: 0}}>
            {props.row.n}
          </Typography>
          <Typography>
            {props.row.ejercicio}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor: banHecho === true? '#ccff90':''}}>    

          <Grid container xs={12} style={{alignItems:'center'}}>
            <Grid item xs={1} style={{color: '#000000', textAlign: 'center'}}>
              <Typography variant="body1">
                <IconButton style={{color:'#000000', cursor: 'default'}}>
                  <AutoAwesomeMotionIcon />
                </IconButton>
              </Typography>        
            </Grid>
            <Grid item xs={2} style={{color: '#000000', textAlign: 'center'}}>
              <Typography variant="body1">
              Sets
              </Typography> 
              <Typography variant="body1">
              {props.row.sets}
              </Typography>        
            </Grid>             
            <Grid item xs={1} style={{color: '#000000', textAlign: 'center'}}>
              <Typography variant="body1">
                <IconButton style={{color:'#000000', cursor: 'default'}}>
                  <RepeatIcon />
                </IconButton>
              </Typography>        
            </Grid>
            <Grid item xs={4} style={{color: '#000000', textAlign: 'center'}}>
              <Typography variant="body1">
              Reps
              </Typography> 
              <Typography variant="body1">
              {props.row.rep}
              </Typography>        
            </Grid>             
            <Grid item xs={1} style={{color: '#000000', textAlign: 'center'}}>
              <Typography variant="body1">
                <IconButton style={{color:'#000000', cursor: 'default'}}>
                  <RepeatOnIcon />
                </IconButton>
              </Typography>        
            </Grid>
            <Grid item xs={3} style={{color: '#000000', textAlign: 'center'}}>
              <Typography variant="body1">
              RER
              </Typography> 
              <Typography variant="body1">
              {props.row.rer}
              </Typography>        
            </Grid>  
            <Grid item xs={12} style={{backgroundColor: '#757575', color: '#ffffff', textAlign: 'center'}}>
              <Typography variant="body1">              
              {'Descanso' + props.row.descanso}
              </Typography>
            </Grid>
          </Grid>  

          <Grid container xs={12} style={{alignItems:'center', borderTop: '1px solid #757575', marginTop: '3%'}}>
            <Grid item xs={12} style={{marginTop: '3%'}}>
              <Typography variant="subtitle2" >
              Configuración recomendada:
              </Typography>
            </Grid>
            {renderConfigRecom()}
            <Grid item xs={12}>
              <Typography variant="body2" style={{color: '#0277bd'}}>
              Basada en el reporte previo
              </Typography>
            </Grid>
          </Grid>          
          
          <Grid container xs={12} spacing={2} justifyContent={'center'} style={{borderTop: '1px solid #757575', marginTop: '3%'}} >
            <Grid item xs={12}>
              <Typography variant="subtitle2">
              Introduce tu reporte
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField label="Carga" variant="outlined" style={{width:'100%'}}               
                value={peso}
                onChange={(event) => setPeso(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField label="Unidad" variant="outlined" style={{width:'100%'}}               
                value={medida}
                onChange={(event) => setMedida(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField label="Rep" variant="outlined" style={{width:'100%'}}               
                value={reps}
                onChange={(event) => setReps(event.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField label="RER" variant="outlined" style={{width:'100%'}}               
                value={repsRer}
                onChange={(event) => setRepsRer(event.target.value)}              
              />
            </Grid>
            {renderBtnRegistro()}
          </Grid>   

          {renderHistorialActual()}
          {renderHistorialPrevio()}
          
        </AccordionDetails>
      </Accordion>
    </>
  );
}