import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Unstable_Grid2';
import axios from "axios";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function DialogoPagoEditar(props) { 
  const [tamDialog, setTamDialog] = React.useState('xs');
  const [fecha, setFecha] = React.useState(null);  
  const [semanas, setSemanas] = React.useState(''); 
  const [semanasError, setSemanasError] = React.useState('');
  const [monto, setMonto] = React.useState(''); 
  const [montoError, setMontoError] = React.useState('');

  const handleClose = () => {
    props.setOpen(false)
  };  

  useEffect(() => {
    setFecha(dayjs(props.arrayDatosAfectados.fecha));
    setSemanas(props.arrayDatosAfectados.semanas);
    setMonto(props.arrayDatosAfectados.pago_monto);
  }, [props.userArray.id]);

  function guardar(){   
    props.setDialogNotificacionOpen(true);
    props.setDialogNotificacionTipo('Carga');
    props.setDialogNotificacionMensaje('');

    var ban = 0;

    if (fecha === null) { ban = 1; } 
    if (semanas === "") { setSemanasError('error'); ban = 1; } else { setSemanasError(''); }
    if (monto === "") { setMontoError('error'); ban = 1; } else { setMontoError(''); }

    if(ban === 0){
      let resultado = parseFloat(semanas);  

      if(!Number.isNaN(resultado)){
        // console.log('numero')
        let a = resultado - semanas;
        // console.log(a)
        if(a !== 0){
          // console.log('diferentes')
          setSemanasError('error');
          ban = 2;
        }
      } else {
        // NO ES NUMERO
        // console.log('no es numero')
        setSemanasError('error');
        ban = 2;
      }

      let resultado2 = parseFloat(monto);  
      // console.log(resultado + ' VS ' + horas) 

      if(!Number.isNaN(resultado2)){
        // console.log('numero')
        let a = resultado2 - monto;
        // console.log(a)
        if(a !== 0){
          // console.log('diferentes')
          setMontoError('error');
          ban = 2;
        }
      } else {
        // NO ES NUMERO
        // console.log('no es numero')
        setMontoError('error');
        ban = 2;
      }

      if(Number.isNaN(resultado) && resultado !== semanas){   
        console.log(!Number.isNaN(resultado));
      }
      
      if(Number.isNaN(resultado2) && resultado2 !== monto){   
        console.log(!Number.isNaN(resultado2));
      }
    }

    if(ban === 0){
      axios.post(props.ruta + "backend/clientes/pago_editar.php",{
        userId: props.userArray.id,
        idCliente: props.arrayDatos.id,
        idPago: props.arrayDatosAfectados.id,
        banActual: props.arrayDatosAfectados.actual,
        fecha: fecha,
        semanas: semanas,
        monto: monto
      })
      .then(result => {
        var data = result.data;

        if(data[0].error === 0) {
          props.setDialogNotificacionOpen(false);
          props.getDatos();
          props.setBuscar(props.arrayDatos.usuario);
          props.getDatos2(props.arrayDatos.usuario);
          props.setOpen(false);
        } else {
          props.setDialogNotificacionTipo('Error');
          props.setDialogNotificacionMensaje("Se ha producido un error al momento de guardar, tome una captura de pantalla y contacte al administrador");
        }
      })
    } else if(ban === 1){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('No se aceptan campos vacios');
    } else if(ban === 2){
      props.setDialogNotificacionTipo('Alerta');
      props.setDialogNotificacionMensaje('Solo se aceptan números en los campos de "Semanas" y "Monto"');
    } 
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth={tamDialog}
        fullWidth={true}
      >        
        <DialogTitle id="customized-dialog-title" sx={{ m:0, p:2}} style={{backgroundColor: '#082342', color: '#ffffff' }}>
        Ediar pago de {props.arrayDatos.nombre + ' ' + props.arrayDatos.aPaterno+ ' ' + props.arrayDatos.aMaterno}
        </DialogTitle>        
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>

          <Grid container spacing={2}>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'MobileDatePicker',
                    'DesktopDatePicker',
                    'StaticDatePicker',
                  ]}
                >
                  <MobileDatePicker 
                    label="Fecha inicial" 
                    value={fecha}
                    onChange={(newValue) => setFecha(newValue)}
                    slotProps={{
                      textField: {
                        helperText: 'Mes / Día / Año'
                      }
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>   
            </Grid>

            <Grid item xs={12}>
              <TextField id="semanas" label="Semanas" variant="outlined" style={{width:'100%'}}               
                value={semanas}
                onChange={(event) => setSemanas(event.target.value)}
                error={semanasError}
              />
            </Grid>  

            <Grid item xs={12}>
              <TextField id="monto" label="Monto" variant="outlined" style={{width:'100%'}}               
                value={monto}
                onChange={(event) => setMonto(event.target.value)}
                error={montoError}
              />
            </Grid>          
          </Grid>

        </DialogContent>

        <DialogActions>          
          <Button onClick={guardar} variant="contained" style={{backgroundColor: '#082342', color: '#ffffff'}}>
            Aceptar
          </Button>
        </DialogActions>

      </BootstrapDialog>
    </React.Fragment>
  );
}