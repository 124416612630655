import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { SentimentVeryDissatisfied, SentimentVerySatisfied } from '@mui/icons-material';

export default function Home(props) {  
  const cookies = new Cookies(null, { path: '/' });
  const navigate = useNavigate();
  const [uAlta, setUAlta] = React.useState(0);
  const [uBaja, SetUBaja] = React.useState(0);

  useEffect(() => {    
    if(props.login === false || props.login === undefined){
      props.setLogin(cookies.get('sethmon_login'));
      props.setUserArray(cookies.get('sethmon_userArray'));
      if(cookies.get('sethmon_login') === false || cookies.get('sethmon_login') === null || cookies.get('sethmon_login') === undefined){        
        navigate('/');
      } 
    } else {
      if(cookies.get('sethmon_tipo') === 2 || cookies.get('sethmon_tipo') === '2'){        
        navigate('/homeCliente');
      } else if(!props.checkPermisos("1") && !props.checkPermisos("3")) {                    
        navigate('/');
      } 
    }      
    props.setPagina('home'); cookies.set('sethmon_pagina', 'home');
  }, []);

  useEffect(() => {    
    getUsuarios();
  }, [props.userArray.id]);

  function getUsuarios(){
    axios.post(props.ruta + "backend/home/get_usuarios.php",{
      userId: props.userArray.id
    })
    .then(result => {
      var data = result.data;

      if(data[0].error === 0) {
        setUAlta(data[0].alta);
        SetUBaja(data[0].baja);
      } 
    })
  }
  
  return (
    <Box sx={{ minHeight: '100vh' }} >
      <Grid container spacing={2}>
        
        <Grid xs={3}>  
          <Card>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} style={{textAlign: 'center'}}>                
                <Typography variant="h4" component="div">                  
                  Usuarios
                </Typography>
              </Grid>
              <Grid item xs={6} style={{backgroundColor: "#c8e6c9", textAlign: 'center'}}>
                <SentimentVerySatisfied />
                <Typography variant="h6" component="div">                  
                  {"Alta: " + uAlta}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{backgroundColor: "#ffcdd2", textAlign: 'center'}}>
                <SentimentVeryDissatisfied />
                <Typography variant="h6" component="div">                  
                {"Baja: " + uBaja}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
           
      </Grid>

    </Box>
  );
}